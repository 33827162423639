import styled from 'styled-components'
import ZigZagBorderAsset from '../images/zigzag-border.svg'
import ZigZagBorderAssetPink from '../images/zigzag-border-pink.svg'

export enum DividerColor {
  Blue = ZigZagBorderAsset,
  Pink = ZigZagBorderAssetPink,
}

interface DividerProps {
  assetColor: DividerColor
}

const Divider = styled.hr<DividerProps>`
  border: 0;
  height: 8px;
  background-image: url(${({ assetColor }) => assetColor});
  background-color: transparent;
  background-size: contain;
`

export default Divider
