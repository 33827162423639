const colors = {
  darkBlue: '#143B52',
  lightBlue: '#EAF5F5',
  lightPink: '#FEF8F9',
  darkPink: '#ff5e6c',
  lightGreen: '#E9F6DF',
}

const spacings = {
  vertical: '5.5rem',
  horizontal: '5.5rem',
  horizontalMobile: '5vw',
}

const breakpointsDown = {
  mobile: '(max-width: 420px)',
  middle: '(max-width: 580px)',
  tablet: '(max-width: 740px)',
  desktop: '(max-width: 980px)',
  wide: '(max-width: 1300px)',
}

const fonts = {
  brown: "'Brown', sans-serif",
}

export { colors, spacings, fonts, breakpointsDown }
