import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../styles/constants'
import { Link } from 'gatsby'

const commonButtonStyles = `
  background-color: ${colors.darkBlue};
  color: white;
  font-weight: bold;
  padding: 1.3rem 3rem 1.2rem 3rem;
  border-radius: 3rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
`

const Button = styled.button<ButtonWrapperProps>`
  width: ${({ wide }) => (wide ? '100%' : 'auto')};
  border: none;
  cursor: pointer;
  font-family: ${fonts.brown};

  &:hover {
    background-color: #0b2230;
  }
  &:active {
    background-color: #081a25;
    transform: scale(0.95);
  }
  &:disabled {
    cursor: default;
    background-color: gray;
  }
  ${commonButtonStyles}
`

const CustomLink = styled(Link)`
  text-decoration: none;
`

const LinkButtonWrapper = styled.div<ButtonWrapperProps>`
  width: ${({ wide }) => (wide ? '100%' : 'auto')};

  &:hover {
    background-color: #0b2230;
  }
  &:active {
    background-color: #081a25;
    transform: scale(0.95);
  }
  ${commonButtonStyles}
`

interface ButtonWrapperProps {
  wide: boolean
}

interface LinkButtonProps {
  wide: boolean
  text: string
  link: string
}

const LinkButton: React.FC<LinkButtonProps> = ({ wide, text, link }) => (
  <CustomLink to={link}>
    <LinkButtonWrapper wide={wide}>{text}</LinkButtonWrapper>
  </CustomLink>
)

export { LinkButton, Button }
